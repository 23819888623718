$color1: #E0F9FF;
$color2: #404040;
$color3: #595757;
$color4: #FB8500;
$color5: #FAC436;
$color6: #FFFCF4;
$bluecolor: #084A6C;
$bluecolor2: #00314A;
$bluecolor3: #D0E3ED;

// common css 

.MarginTop {
    margin-top: 100px;
}

.heading {
    h2 {
        text-align: center;
        color: $bluecolor;
        font-size: 40px;
        font-weight: 400;
    }
}

// custom css 

.HomePageBanner-section {
    .backgroundImage {
        background-image: url('../Assets/grpbackgound-image.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 660px;
        padding: 60px 0px 40px 0px;

        .text {
            h4 {
                font-size: 30px;
                font-weight: 600;
                color: $bluecolor;

                span {
                    color: $color1;
                }

            }

            h1 {
                font-size: 40px;
                font-weight: 600;
                color: $color1 ;
                margin: 45px 0px;

                span {
                    color: $bluecolor;
                }
            }

            h6 {
                line-height: 30px;
                font-size: 20px;
                font-weight: 400;
                color: $color2;
            }
        }
    }

}

.aboutus-section {
    margin: 150px 0px;

    .text {
        text-align: center;

        h5,
        h6 {
            font-size: 20px;
            font-weight: 400;
            color: $color3;
            margin-top: 20px;
        }

        h3 {
            font-size: 28px;
            color: $color4;
            margin-top: 20px;
            font-weight: 400;
        }

    }
}

.Product-section {
    margin: 150px 0px;

    .box {
        padding: 40px 50px;
        position: relative;

        .subbox {
            border-radius: 12px;
            padding: 30px;
            background: linear-gradient(180deg, rgba(204, 237, 252, 0.44) 0%, rgba(255, 183, 3, 0.2332) 100%);

            .imagebox {
                position: absolute;

                &.box1 {
                    top: 0;
                    left: 0;
                    width: 40%;
                }

                &.box2 {
                    bottom: 0;
                    right: 0;
                    width: 40%;
                }

                .images {
                    background-color: $color5;
                    border-radius: 12px;
                    padding: 20px;
                }
            }

            .text {
                padding: 20px;

                h4 {
                    color: $bluecolor2;
                    font-size: 28px;
                    font-weight: 600;
                }

                h6 {
                    font-size: 20px;
                    font-weight: 400;
                    color: $bluecolor2;
                    line-height: 28px;
                }
            }
        }
    }
}

.features-section {
    margin: 150px 0px;

    .box {
        padding: 20px;

        .subbox {
            cursor: pointer;
            height: 220px;
            padding: 20px;
            box-shadow: 20px 20px 50px 0 rgba(0, 0, 0, .051);
            ;
            background-color: $color6;
            border-radius: 8px;
            transition: box-shadow .3s ease-in;

            h3 {
                font-size: 20px;
                font-weight: 400;
                margin-bottom: 20px;
            }

            h5 {
                font-size: 20px;
                font-weight: lighter;
            }

            &:hover {
                box-shadow: 5px 5px 0px 1px #219EBC;
            }
        }
    }
}

.sourcingPartner-section {
    .content {
        margin-top: 40px;
        background: linear-gradient(90deg, rgba(255, 183, 3, 0.2279) 0%, rgba(142, 202, 230, 0.43) 100%);
        padding: 30px 0px 0px 0px;

        .box {
            padding: 60px 50px;
            // padding: 60px 100px;

            .subbox {
                .images {
                    border-radius: 12px;
                }

                .text {
                    padding: 35px 0px;
                    text-align: center;

                    h4 {
                        color: $bluecolor2;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 28px;
                        margin-bottom: 40px;
                    }

                    .button {
                        background-color: $bluecolor3;
                        color: $bluecolor2;
                        padding: 12px 20px;
                        font-size: 20px;
                        font-weight: 400;
                        border: none;
                        border-radius: 8px;
                        text-decoration: none;

                        &:hover {
                            background-color: $bluecolor2;
                            color: $bluecolor3;
                            border: 1px solid $bluecolor2;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width :1500px) {
    .HomePageBanner-section {
        .backgroundImage {
            height: 1000px;
        }
    }
}

@media screen and (max-width:1200px) {
    .HomePageBanner-section {
        .backgroundImage {
            height: max-content;
        }
    }

    .Product-section {
        .box {
            padding: 40px 0px;
            position: relative;

            .subbox {
                padding: 30px;

                .imagebox {
                    position: unset;

                    &.box1,
                    &.box2 {
                        width: 100%;
                    }

                }
            }
        }
    }

    .features-section {
        .box {
            .subbox {
                height: 300px;
            }
        }
    }

    .sourcingPartner-section {
        .content {
            .box {
                .subbox {
                   
                    .text {
                        padding: 15px 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width : 991px) {
    .Product-section {
        .box {
            .subbox {
                #productSection1 {
                    order: 2;
                }

                #productSection2 {
                    order: 1;
                }

                .imagebox {

                    &.box1,
                    &.box2 {
                        width: 70%;
                    }

                }
            }
        }
    }

    .sourcingPartner-section {
        .content {
            padding: 30px 0px 0px 0px;
    
            .box {
                padding: 30px 50px;
    
                .subbox {                   
                    .text {
                        padding: 15px 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width :576px) {
    .Product-section {
        .box {
            .subbox {
                padding: 30px 6px;

                .imagebox {

                    &.box1,
                    &.box2 {
                        width: 80%;
                    }

                }

                .text {
                    padding: 20px 6px;

                    h4 {
                        font-size: 24px;
                    }

                    h6 {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .sourcingPartner-section {
        .content {
            .box {
                padding: 0px;
                .subbox{
                    padding: 30px 0px;
                }
            }
        }
    }
}